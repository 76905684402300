<template>
    <div class="discover-list-body">
        <h1 class="discover-title">{{ $t('headline.discover') }}</h1>
        <div class="discover-list">
            <ul>
                <li :key='key' v-for="(discover,key) in discoverList">
                    <img class="discover-img" @click="onLink(discover)" :src="discover.coverImage" v-lazy="discover.coverImage"/>
                    <div class="discover-text">
                        <p class="text-row">{{ discover.coverTitle }}</p>
                        <p class="text-title">{{ discover.keywords }}</p>
                        <p class="text-row">{{ discover.coverSubtitle }}</p>
                    </div>
                </li>
            </ul>
            <div class="discover-loading" v-if="loadingVisible"></div>
        </div>
    </div>
</template>
<script>
import DiscoverList from './list'
export default DiscoverList
</script>
<style lang="less">
@import "list";
</style>
