/**
 * 发现列表
 */
import Vue from 'vue'
import { getDiscoverListService } from "@/service/market-service";
import { mapMutations, mapState } from "vuex";
import VueLazyLoad from 'vue-lazyload'
import { SOURCE_NAME } from "@/service/public/enum";
Vue.use(VueLazyLoad,{
    loading:require('@/assets/Loading/seize-seat-img.jpg')
})
const DiscoverList = {
    name:'DiscoverList',
    computed: {
        ...mapState({
            menuType: state => state.product.menuType //获取状态管理菜单分类
        }),
    },
    data() {
        return {
            loadingVisible:false,
            discoverList:[],
            discoverParams:{
                currentPage:1,
                pageSize:12
            },
        }
    },
    created() {
        this.onDiscoverList().then()
    },
    mounted() {
        document.documentElement.scrollTop = document.body.scrollTop = 0 //定位滚动条到头部
    },
    methods: {
        ...mapMutations('product',['SET_PRODUCT_ID','SET_MENU_ID','SET_SELECTION_ID','SET_SOURCE','SET_SECOND_TYPE','SET_TERTIARY_TYPE']),
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.discoverParams.currentPage = number
            this.onDiscoverList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.discoverParams.pageSize = number
            this.onDiscoverList().then()
        },
        /**
         * 跳转
         * @param obj
         */
        onLink(obj){
            if(obj.contentType ===3){
                this.SET_SELECTION_ID(obj.selectionId)
                this.SET_MENU_ID(obj.id)
                this.SET_SOURCE(SOURCE_NAME.SUPER_SALE)
                this.SET_SECOND_TYPE({
                    categoryId:'',
                    name:''
                })
                this.SET_TERTIARY_TYPE({
                    categoryId:'',
                    name:'',
                })
                this.$router.push('/product/list')
            }else{
                this.SET_PRODUCT_ID(obj.id)
                this.$router.push('/image/text')
            }
        },
        /**
         * 拉取发现列表
         * @returns {Promise<void>}
         */
        async onDiscoverList() {
            try {
                this.loadingVisible = true
                let params = {
                    'cid1':this.menuType,
                }
                const data = await getDiscoverListService(params)
                this.discoverList = data
                this.loadingVisible = false
            }catch (error){
                this.loadingVisible = false
                console.log(error)
            }
        },
    },
}
export default DiscoverList
